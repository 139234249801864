import React, { Component } from 'react';

import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Configure,
  MenuSelect,
  InfiniteHits,
  RefinementList,
  ClearRefinements,
} from 'react-instantsearch-dom';
import {
  GeoSearch,
  CustomMarker,
  GoogleMapsLoader,
} from 'react-instantsearch-dom-maps';
import { AlgoliaAutoItem } from './AlgoliaAutoItem';
import { AlgoliaAutoComplete } from './AlgoliaAutocomplete';

import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  ListingCard,
  Breadcrumbs,
  IconMapMarker,
  Modal,
} from '../../components';
import { TopbarContainer } from '..';

import {
  pickSearchParamsOnly,
  createSearchResultSchema,
} from './AlgoliaSearchPage.helpers';

import css from './AlgoliaSearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  {
    _useRequestCache: true,
  }
);

const limitsPerFacet = 30;
const updateAfter = 700;
const searchStateToUrl = (searchState) =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

export class AlgoliaSearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      showMap: false,
      rangeValue: [40],
      searchState: typeof window !== 'undefined' && qs.parse(window.location.search.slice(1)),
      toggle: false,
      isSecondaryFiltersOpen: false
    };

    this.searchMapListingsInProgress = false;
    this.handleToggleState = this.handleToggleState.bind(this);
    this.onSearchStateChange = this.onSearchStateChange.bind(this);
    typeof window !== 'undefined' && window.addEventListener('popstate', ({ state: searchState }) => {
      this.setState({ searchState });
    });
  }

  handleToggleState = () => {
    this.setState({ toggle: true });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.searchState && typeof window !== 'undefined' && qs.parse(window.location.search.slice(1)) && !this.state.hitFromPage) {
  //     if (prevState.searchState.query != qs.parse(window.location.search.slice(1)).query) {
  //       this.setState({ searchState: qs.parse(window.location.search.slice(1)) })
  //     }
  //   }
  // }




  onSearchStateChange = (searchState) => {
    // update the URL when there is a new search state.
    clearTimeout(this.debouncedSetState);
    this.debouncedSetState = setTimeout(() => {
      typeof window !== 'undefined' && window.history.pushState(
        searchState,
        null,
        searchStateToUrl(searchState)
      );
    }, updateAfter);

    this.setState((previousState) => {
      const hasQueryChanged =
        previousState.searchState.query !== searchState.query;

      return {
        ...previousState,
        searchState: {
          ...searchState,
          boundingBox: !hasQueryChanged ? searchState.boundingBox : null,
        },
      };
    });
  };

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      scrollingDisabled,
      params,
      pageName,
      searchParams,
      currentUser,
      currentUserListing,
      currentUserListingFetched,
      categories,
      subCategories,
      showList,
      subChildCategories,
      onManageDisableScrolling,
      // onAddOrRemoveToConnected,
    } = this.props;

    if (typeof window === undefined) return null;
    const { getAlgoliaResults } = require('@algolia/autocomplete-js');
    // eslint-disable-next-line no-unused-vars
    // const { mapSearch, page, } = parse(location.search, {
    //   latlng: ['origin'],
    //   latlngBounds: ['bounds'],
    // });
    const searchInURL = typeof window != "undefined" ? parse(window.location.search) : {};
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);



    const redirectTo = (link) => {
      this.onSearchStateChange({
        configure: { aroundLatLngViaIP: true, aroundRadius: 'all', hitsPerPage: 12, maxValuesPerFacet: limitsPerFacet },
        page: 1,
        refinementList: { 'location.address': link }
      });
    }

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };
    const handleShowHide = () => {
      if (this.state.showMap) {
        this.setState({ showMap: false })
      } else {
        this.setState({ showMap: true })
      }
    }
    const toggleSecondaryFiltersOpen = () => {
      if (this.state.isSecondaryFiltersOpen) {
        this.setState({ isSecondaryFiltersOpen: false })
      } else {
        this.setState({ isSecondaryFiltersOpen: true })
      }
    }

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const { searchState } = this.state;

    const parameters = {};
    if (!searchState.boundingBox) {
      parameters.aroundLatLngViaIP = true;
      parameters.aroundRadius = 'all';
      parameters.hitsPerPage = 12;
      parameters.maxValuesPerFacet = limitsPerFacet;
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.mainWrapper}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="AlgoliaSearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <div className={classNames(css.aisInstantSearch, currentUser ? "" : css.notLoginUserSearch)}>
            <InstantSearch
              searchClient={searchClient}
              indexName={process.env.REACT_APP_ALGOLIA_LISTING_INDEX || 'VendorTree' || 'airbnb' || 'demo_ecommerce'}
              searchState={searchState}
              onSearchStateChange={this.onSearchStateChange}
            >
              <Configure {...parameters} />
              <div className={classNames(css.leftPanel, this.state.showMap ? css.fullLeftPanel : "")}>
                {/* <div className={css.breadcrumbsBox}>
                  <Breadcrumbs
                    lvl1={{ name: "AlgoliaSearchPage", label: "Search" }}
                    lvl2={searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[categoriesLabel]']
                      ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[categoriesLabel]'], search: `refinementList[categoriesLabel]=${searchInURL['refinementList[categoriesLabel]']}` }
                      : searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[categoriesLabel][0]']
                        ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[categoriesLabel][0]'], search: `refinementList[categoriesLabel][0]=${searchInURL['refinementList[categoriesLabel][0]']}` }
                        : ''}
                    lvl3={searchInURL && Object.keys(searchInURL).length && searchInURL['refinementList[subCategoriesLabel][0]']
                      ? { name: "AlgoliaSearchPage", label: searchInURL['refinementList[subCategoriesLabel][0]'], search: `refinementList[subCategoriesLabel][0]=${searchInURL['refinementList[subCategoriesLabel][0]']}` }
                      : ''}
                  />
                </div> */}
                <div className={css.searchFilter}>
                  <div className={css.keywordFilter}>
                    <SearchBox
                      translations={{
                        placeholder: 'Keyword',
                      }}
                    />
                  </div>
                  {this.state.showMap
                    ? <div className={css.dateSelect}>
                      <MenuSelect
                        limit={limitsPerFacet}
                        attribute="trades.label"
                        transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      />
                    </div>
                    : null}


                  <div className={css.filterBox}
                    onClick={() => {
                      toggleSecondaryFiltersOpen();
                    }}
                  >
                    <IconMapMarker type="filter" />
                  </div>
                  <div className={css.mapToogleButton}>
                    <div className={css.mapLabel}>
                      {this.state.showMap ? "Show Map" : "Hide Map"}
                    </div>
                    <div className={css.checkboxWrapper}
                      onClick={handleShowHide}>
                      <div
                        className={classNames(css.ball, {
                          [css.toggled]: !this.state.showMap,
                        })}
                      >
                        {' '}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={css.selectCategoryData}>
                  <RefinementList limit={limitsPerFacet} attribute="trades.label" transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="businessName" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="location.address" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="location.address" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>

                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="language.key" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="localHireTrade" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="price.amount" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="trades.inputValues.startedYear.label" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="trades.inputValues.level.label" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                {/* <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="insurance" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div> */}
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="user.gender" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="availabilityPlan.entries.dayOfWeek" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>

                <ClearRefinements
                  className={css.filterClearButton}
                />
                <div className={css.dividerLine} />
                <div className={classNames(
                  css.cardsGrid, this.state.showMap ? css.fullCardGrid : "")}>
                  <InfiniteHits hitComponent={(props) => <ListingCard
                    {...props}
                    key={props.hit.objectID}
                    currentUser={currentUser}
                    categories={categories}
                    subCategories={subCategories}
                  />}
                  />
                </div>
              </div>
              {this.state.showMap || isMobileLayout
                ? null
                : <div className={css.rightPanel}>
                  <div className={css.rightPanelContent}>
                    <div className={css.searchMapInput}><AlgoliaAutoComplete
                      insights={true}
                      openOnFocus={true}
                      defaultActiveItemId={0}
                      getSources={({ query }) => [
                        {
                          sourceId: 'location.address',
                          getItems() {
                            return getAlgoliaResults({
                              searchClient,
                              queries: [
                                {
                                  indexName: 'TheProjectGarageDev',
                                  query,
                                },
                              ],
                            });
                          },
                          templates: {
                            item({ item, components }) {
                              // return <span
                              //   onClick={() => redirectTo(item.location.address)}
                              //   className="aa-ItemLink">
                              //   {item.location.address}
                              // </span>

                              return <AlgoliaAutoItem hit={item} redirectTo={redirectTo} components={components} />

                            },
                          },
                          getItemInputValue({ item }) {
                            return item.location.address;
                          },
                        },
                      ]}
                    />
                    </div>
                    <div className={css.mapRightBar}>
                      <GoogleMapsLoader  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
                        {/* "AIzaSyBawL8VbstJDdU5397SUX7pEt9DslAwWgQ" */}
                        {(google) => (
                          <GeoSearch initialZoom={3} google={google} enableRefineOnMapMove={false} >
                            {({ hits }) => (
                              hits.map((hit) => (
                                <CustomMarker   key={hit.objectID} hit={hit}>
                                  <span onClick={() =>{
                                  this.onSearchStateChange({
                                    configure: { aroundLatLngViaIP: true, aroundRadius: 'all', hitsPerPage: 12, maxValuesPerFacet: limitsPerFacet },
                                    page: 1,
                                    refinementList: { 'businessName': hit.businessName }
                                  });
                                  }} style={{ backgroundColor: "#FFF", fontSize: "1rem" }}>
                                    <IconMapMarker type="markerone" />
                                  </span>
                                </CustomMarker>
                              ))
                            )}
                          </GeoSearch>
                        )}
                      </GoogleMapsLoader>
                    </div>
                  </div>
                </div>}
              <Modal
                id="AlgoliaSearchPage.MenuList"
                isOpen={this.state.isSecondaryFiltersOpen}
                onClose={() => this.setState({ isSecondaryFiltersOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >

                <div className={css.filtersList}>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Language</div>
                    <MenuSelect
                      attribute="language.label"
                      limit={limitsPerFacet}
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}

                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Location</div>
                    <MenuSelect
                      limit={limitsPerFacet}
                      attribute="location.address"
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Local Hire</div>
                    <MenuSelect
                      limit={limitsPerFacet}
                      attribute="localHireTrade"
                      // transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: item.label == 'true' ? 'Available for local hire' : 'Not Available for local hire'
                      }))}
                    />
                  </div>
                  {this.state.showMap
                    ? null
                    : <div className={css.filterBox}>
                      <div className={css.labelName}>Trade Category</div>
                      <MenuSelect
                        attribute="trades.label"
                        limit={limitsPerFacet}
                        transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      />
                    </div>}
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Price Range</div>
                    <MenuSelect
                      attribute="price.amount"
                      limit={limitsPerFacet}
                      // transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: '$ ' + item.label / 100
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Years of Exp</div>
                    <MenuSelect
                      limit={limitsPerFacet}
                      attribute="trades.inputValues.startedYear.label"
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Level of Experience</div>
                    <MenuSelect
                      limit={limitsPerFacet}
                      attribute="trades.inputValues.level.label"
                      transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                    />
                  </div>
                  {/* <div className={css.filterBox}>
                    <div className={css.labelName}>Insurance</div>
                    <MenuSelect
                      limit={limitsPerFacet}
                      attribute="insurance"
                      // transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: item.label,
                      }))}
                    />
                  </div> */}
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Gender</div>
                    <MenuSelect
                      attribute="user.gender"
                      limit={limitsPerFacet}
                      // transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: item.label,
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Availability </div>
                    <MenuSelect
                      attribute="availabilityPlan.entries.dayOfWeek"
                      limit={limitsPerFacet}
                      // transformItems={(items) => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: item.label == 'mon' ? 'Monday' : item.label == 'tue' ? 'Tuesday' : item.label == 'wed' ? 'Wednesday' : item.label == 'thu' ? 'Thursday' : item.label == 'fri' ? 'Friday' : item.label == 'sat' ? 'Saturday' : 'Sunday'
                      }))}
                    />
                  </div>
                </div>
                <div className={css.bottomButton}>
                  <ClearRefinements
                    className={css.clearLink}
                  />
                </div>
              </Modal>
            </InstantSearch>
          </div>
        </div >
      </Page >
    );
  }
}

AlgoliaSearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
  initialSearchFormValues: {},
};

AlgoliaSearchPageComponent.propTypes = {
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  initialSearchFormValues: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AlgoliaSearchPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AlgoliaSearchPageComponent);

export default AlgoliaSearchPage;
